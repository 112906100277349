import React, { useState } from 'react';
import { useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import NumberField from '../../common/NumberField';
import NewCheckbox from '../../common/NewCheckbox';
import NewChips from '../../common/NewChips';

const lastFloor = [
  {
    value: 'false',
    id: 'new-search-not-last',
    name: 'new_search.not_last'
  },
  {
    value: 'true',
    id: 'new-search-only-last',
    name: 'new_search.last_floor'
  }
];

const NewSearchFloors = ({ valueFirst, valueLast }) => {
  const { t } = useTranslation();
  const { change, getFieldState } = useForm();
  const [prevValue, setPrevValue] = useState(null);

  const handleCheckboxChange = event => {
    const floorFirstChecked = event.target.checked;
    const fieldState = getFieldState('floor_from');
    const { value: fieldValue } = fieldState;

    if (floorFirstChecked && +fieldValue === 1) {
      change('floor_from', undefined);
    } else if (!floorFirstChecked) {
      change('floor_first', undefined);
    }
  };

  const onChangeRadioHandler = event => {
    const currentValue = event.target.value;

    if (prevValue === currentValue) {
      change('floor_last', undefined);
    }
    if (typeof currentValue !== 'undefined') setPrevValue(currentValue);
  };

  return (
    <div className="filters__section">
      <p className="filters__secondary-text__number">
        {t('search.form.floor')}
      </p>
      <div className="filters__fields-container">
        <NumberField
          name="floor_from"
          id="new-search-form-floor-from"
          placeholder={t('search.form.from')}
        />
        <NumberField
          name="floor_to"
          placeholder={t('search.form.to')}
          id="new-search-form-floor-to"
        />
      </div>
      <div className="filters__options-wrapper">
        <NewCheckbox
          key="floor_first"
          name="floor_first"
          storedValue={valueFirst}
          text={t('new_search.not_first')}
          stat="new-search-form-not-first-floor"
          handleChange={handleCheckboxChange}
        />
        {lastFloor.map(floor => (
          <NewChips
            key={floor.id}
            type="radio"
            name="floor_last"
            value={floor.value}
            text={t(floor.name)}
            storedValue={valueLast}
            onClick={onChangeRadioHandler}
            stat="new-search-form-last-floor"
          />
        ))}
      </div>
    </div>
  );
};

NewSearchFloors.propTypes = {
  t: PropTypes.func,
  valueLast: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  valueFirst: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

export default NewSearchFloors;
