import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { Field, useFormState } from 'react-final-form';
import { cityQuery } from '../../../queries';
import Loader from '../../Loader';
import { setError } from '../../../actions';
import NewSearchPopup from '../NewSearchPopup';
import NewSearchCity from './NewSearchCity';

const NewSearchCities = ({
  toggleHandlerCities,
  citiesPopupIsOpen,
  setCitiesLoading
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { values } = useFormState();
  const currentCity = useSelector(state => state.searchForm.currentCity);
  const cityId = {
    id: values.city_id || currentCity
  };
  const { data, loading, error } = useQuery(cityQuery(cityId), {
    variables: cityId
  });

  useEffect(() => {
    if (error) dispatch(setError(true));
  }, [error]);

  const cityName =
    data && data.city != null ? data.city.name : t('search.city_search.any');

  useEffect(() => {
    setCitiesLoading(loading);
  }, [loading]);

  return (
    <>
      <Field name="city_id" component="input" type="hidden" />
      <div
        tabIndex={0}
        role="button"
        className="city__container"
        onClick={toggleHandlerCities}
        onKeyPress={toggleHandlerCities}
        data-stat="new-search-form-city"
        data-cy="chosen-city"
      >
        <div className="city">
          <div className="city__label">{t('search.city_search.title')}</div>
          <Loader loading={loading} optionalClass="city__loader">
            <div className="city__name">{cityName}</div>
          </Loader>
        </div>
        <NewSearchPopup
          modalId="select-city"
          closeHandler={toggleHandlerCities}
          isOpen={citiesPopupIsOpen}
          popupTitle={t('search.city_search.title')}
          inputPlaceholder={t('new_search.search_city')}
          additionalClass="new-search-popup--cities"
          data-cy="modal-select-city"
        >
          {(searchString, isFocused, cancelHandler) => (
            <NewSearchCity
              closePopupHandler={toggleHandlerCities}
              {...{ isFocused, searchString, cancelHandler }}
            />
          )}
        </NewSearchPopup>
      </div>
    </>
  );
};

NewSearchCities.propTypes = {
  setCitiesLoading: PropTypes.func,
  citiesPopupIsOpen: PropTypes.bool,
  toggleHandlerCities: PropTypes.func
};

export default NewSearchCities;
