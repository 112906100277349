import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import Icon from '../../../Icon';

const NewCheckbox = ({
  id,
  name,
  text,
  stat,
  icon,
  value,
  onClick,
  cypress,
  iconSize,
  storedValue,
  handleChange
}) => (
  <label
    id={id}
    className={`new-checkbox-container${
      storedValue ? ' new-checkbox-active' : ''
    }`}
  >
    <div
      tabIndex={0}
      role="button"
      data-stat={stat}
      onClick={onClick}
      onKeyPress={onClick}
    >
      <Field
        name={name}
        value={value}
        type="checkbox"
        component="input"
        className="display-none"
        inputOnChange={handleChange}
      />
      {icon && (
        <Icon
          color="#FFF"
          width={iconSize}
          height={iconSize}
          IconComponent={icon}
        />
      )}
      <span className="new-checkbox__text" data-cy={cypress}>
        {text}
      </span>
    </div>
  </label>
);

NewCheckbox.propTypes = {
  id: PropTypes.string,
  icon: PropTypes.func,
  stat: PropTypes.string,
  name: PropTypes.string,
  text: PropTypes.string,
  value: PropTypes.string,
  onClick: PropTypes.func,
  cypress: PropTypes.string,
  iconSize: PropTypes.number,
  storedValue: PropTypes.bool,
  handleChange: PropTypes.func
};

export default NewCheckbox;
