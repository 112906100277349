import React, { useRef, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import BinaModal from '../../ReactModal';
import useSearchModal from '../../../hooks/useSearchModal';
import {
  hidePopupHeader,
  showPopupHeader,
  setCurrentState
} from '../../../actions';
import NewInput from '../common/NewInput';
import FiltersHeader from '../common/FiltersHeader';

const NewSearchPopup = ({
  isOpen,
  modalId,
  topTools,
  children,
  inputMode,
  popupTitle,
  closeHandler,
  additionalClass,
  inputPlaceholder
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const inputEl = useRef(null);
  const formEl = useRef(null);
  const { change } = useForm();
  const isHiddenHeader = useSelector(state => state.searchForm['popup-header']);
  const resetButtonIsDisabled = useSelector(
    state => state.searchForm.resetButtonIsDisabled
  );
  const {
    isFocused,
    searchString,
    onFocusHandler,
    onResetHandler,
    onChangeHandler,
    isSubmittedForm,
    setIsSubmittedForm,
    defaultCancelHandler
  } = useSearchModal(inputEl);

  const setRef = () => {
    return formEl;
  };

  const handleKeyDown = e => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      setIsSubmittedForm(true);
    }
  };

  const cancelHandler = () => {
    dispatch(showPopupHeader());
    defaultCancelHandler();
  };

  const onFocusInputHandler = () => {
    dispatch(hidePopupHeader());
    onFocusHandler();
  };

  const resetHandler = () => {
    change('city_id', '0');
    dispatch(
      setCurrentState({
        city_id: '0'
      })
    );
  };

  return (
    <BinaModal
      isOpen={isOpen}
      modalId={modalId}
      onRequestClose={closeHandler}
      contentClassName={classNames(
        'new-search-popup body-scroll-lock-ignore',
        additionalClass,
        { 'new-search-popup--is-focused': isFocused }
      )}
      overlayClassName="new-search-popup__overlay body-scroll-lock-ignore"
    >
      <FiltersHeader
        title={popupTitle}
        close={closeHandler}
        handleReset={resetHandler}
        isDisabled={resetButtonIsDisabled}
      />
      <div
        className="new-search-popup__content"
        data-cy="new-search-popup-content"
      >
        {isHiddenHeader && topTools}
        {inputPlaceholder && (
          <div
            className={classNames('new-search-popup__tools', {
              'new-search-popup__tools--is-focused': isFocused
            })}
            data-stat="new-search-find-city"
            data-cy="new-search-popup-tools"
          >
            <NewInput
              refEl={inputEl}
              value={searchString}
              inputMode={inputMode}
              onKeyDown={handleKeyDown}
              isShowReset={!!searchString}
              placeholder={inputPlaceholder}
              onResetHandler={onResetHandler}
              id="search-form-search-by-number"
              onChangeHandler={onChangeHandler}
              onFocusHandler={onFocusInputHandler}
            />
            {isFocused && (
              <button
                onClick={cancelHandler}
                className="new-search-popup__content-btn new-search-popup__content-btn--cancel"
              >
                {t('common.cancel')}
              </button>
            )}
          </div>
        )}
        {typeof children === 'function'
          ? children(
              searchString,
              isFocused,
              cancelHandler,
              isSubmittedForm,
              setRef
            )
          : { children }}
      </div>
    </BinaModal>
  );
};

NewSearchPopup.propTypes = {
  isOpen: PropTypes.bool,
  topTools: PropTypes.node,
  modalId: PropTypes.string,
  inputMode: PropTypes.string,
  popupTitle: PropTypes.string,
  closeHandler: PropTypes.func,
  additionalClass: PropTypes.string,
  inputPlaceholder: PropTypes.string,
  children: PropTypes.func || PropTypes.node
};

NewSearchPopup.defaultProps = {
  inputMode: 'text'
};

export default memo(NewSearchPopup);
