import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-final-form';
import NewChips from '../../common/NewChips';

const rentType = [
  {
    value: 'true',
    name: 'search.form.paid_daily',
    id: 'new-search-form-paid-daily'
  },
  {
    value: 'false',
    name: 'new_search.long_term',
    id: 'new-search-form-long-term'
  }
];

const NewRentalPeriod = ({ t, value }) => {
  const { change } = useForm();

  return (
    <div className="filters__section">
      <p className="filters__secondary-text">{t('new_search.rental_type')}</p>
      <div className="filters__options-wrapper">
        {rentType.map(type => (
          <NewChips
            type="button"
            key={type.id}
            stat={type.id}
            value={type.value}
            text={t(type.name)}
            name="paid_daily"
            storedValue={value?.[0]}
            onClick={() => change('paid_daily', [type.value])}
          />
        ))}
      </div>
    </div>
  );
};

NewRentalPeriod.propTypes = {
  t: PropTypes.func,
  value: PropTypes.array
};

export default NewRentalPeriod;
