import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import Icon from '../../../Icon';
import NewChips from '../../common/NewChips';
import FlatIcon from '../../../../public/static/images/new_search/icons/icon-flat.svg';
import { categoriesQuery } from '../../../../queries';
import { setError } from '../../../../actions';
import { fillCategoriesWithData } from '../../../../utils/helpers/search';

const NewSearchCategory = ({ t, value }) => {
  // eslint-disable-next-line
  const active = value && (value === '1') || (value === '2') || (value === '3');
  const dispatch = useDispatch();
  const { data, error } = useQuery(categoriesQuery);
  const categoriesData = data?.categories || [];
  const filledCategories = fillCategoriesWithData(categoriesData);
  const categoriesWithoutFlat = filledCategories.filter(
    category => category.id !== '1'
  );

  useEffect(() => {
    if (error) dispatch(setError(true));
  }, [error]);

  return (
    <div className="filters__section">
      <p className="filters__secondary-text">{t('new_search.type_estate')}</p>
      <div className="filters__options-wrapper">
        <label
          id="category_id"
          data-stat="new-search-form-flat"
          style={{ padding: '8px 15px', height: '40px' }}
          className={`chips-container${active ? ' chips-active' : ''}`}
        >
          <Field
            value="1"
            type="radio"
            component="input"
            name="category_id"
            className="display-none"
          />
          <Icon IconComponent={FlatIcon} width={22} height={22} color="#FFF" />
          <span className="chips__text">{t('new_search.flat_upper')}</span>
        </label>
        {categoriesWithoutFlat.map(category => (
          <NewChips
            iconSize={22}
            type="radio"
            name="category_id"
            storedValue={value}
            value={category.id}
            icon={category.icon}
            text={category.name}
            stat={category.formStat}
            style={{ padding: '8px 15px', height: '40px' }}
            key={`category-${category.id}`}
          />
        ))}
      </div>
    </div>
  );
};

NewSearchCategory.propTypes = {
  t: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default NewSearchCategory;
