import React, { useEffect, Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CheckboxButton from '../../../CheckboxButton';

const CollapsableLocation = ({
  location,
  townships,
  isFullName,
  chosenLocationIds,
  onCheckGroupHandler,
  onChangeCheckboxHandler
}) => {
  const { id } = location;
  const collapsed = false;
  const nestedTownships = (townships ?? []).filter(ts => ts.parent.id === id);
  const handleCheckGroup = useCallback(
    e => {
      e.preventDefault();
      onCheckGroupHandler(
        [...nestedTownships, location],
        !chosenLocationIds.includes(id)
      );
    },
    [chosenLocationIds]
  );
  const hasMultipleOptions = nestedTownships?.length > 0;
  const collapsableClass = classNames('new-search-checkbox-list--dropdown', {
    'new-search-checkbox-list--dropdown__collapsed': collapsed
  });
  const mainClass = classNames('new-search-checkbox-list--dropdown__main', {
    'new-search-checkbox-list--dropdown__main__with-arrow': hasMultipleOptions,
    'new-search-checkbox-list--dropdown__main__with-arrow__collapsed':
      hasMultipleOptions && collapsed
  });
  const labelClass = nestedTownships
    ? 'search-checkbox-label--new-region'
    : null;
  return (
    <Fragment>
      <div aria-checked={!collapsed} className={mainClass}>
        <CheckboxButton
          {...{ labelClass, isFullName }}
          stat="new-search-check-locations"
          data={location}
          onChangeHandler={
            hasMultipleOptions ? handleCheckGroup : onChangeCheckboxHandler
          }
          currentValues={chosenLocationIds}
          name="search-locations"
          cypress="location"
        />
      </div>
      <div className={collapsableClass}>
        {hasMultipleOptions && (
          <>
            {nestedTownships.map(township => (
              <CheckboxButton
                data={township}
                key={township.id}
                cypress="location"
                name="search-locations"
                stat="new-search-check-locations"
                currentValues={chosenLocationIds}
                onChangeHandler={onChangeCheckboxHandler}
                labelClass="new-search-checkbox-label--region"
              />
            ))}
          </>
        )}
      </div>
    </Fragment>
  );
};

CollapsableLocation.propTypes = {
  locationGroupId: PropTypes.string,
  location: PropTypes.shape({
    id: PropTypes.string.isRequired
  }),
  chosenLocationIds: PropTypes.array,
  onChangeCheckboxHandler: PropTypes.func,
  onCheckGroupHandler: PropTypes.func,
  townships: PropTypes.array,
  isFullName: PropTypes.bool
};

const TabContent = ({
  locations,
  chosenLocationIds,
  onChangeCheckboxHandler,
  onCheckGroupHandler,
  setTownshipsCount,
  townships,
  isFullName
}) => {
  const getTownshipsCount = () => {
    if (!townships) return null;
    let locationsCount = 0;
    townships.forEach(township => {
      if (chosenLocationIds.includes(township.id)) locationsCount += 1;
    });

    return locationsCount;
  };

  useEffect(() => {
    townships && setTownshipsCount(getTownshipsCount());
  }, [chosenLocationIds]);

  return (
    <div className="new-search-checkbox-list">
      {locations.map(location => (
        <CollapsableLocation
          key={location.id}
          {...{
            location,
            chosenLocationIds,
            onCheckGroupHandler,
            onChangeCheckboxHandler,
            townships,
            isFullName
          }}
        />
      ))}
    </div>
  );
};

TabContent.propTypes = {
  locationGroupId: PropTypes.string,
  locations: PropTypes.array,
  chosenLocationIds: PropTypes.array,
  onChangeCheckboxHandler: PropTypes.func,
  onCheckGroupHandler: PropTypes.func,
  currentCityId: PropTypes.string,
  setTownshipsCount: PropTypes.func,
  townships: PropTypes.array,
  isFullName: PropTypes.bool
};

export default TabContent;
