import React from 'react';
import PropTypes from 'prop-types';
import NumberField from '../../common/NumberField';

const NewLandAreaFields = ({ t }) => (
  <div className="filters__section">
    <p className="filters__secondary-text__number">
      {t('search.form.land_area')}
    </p>
    <div className="filters__fields-container">
      <NumberField
        name="land_area_from"
        id="new-search-form-land-area-from"
        placeholder={t('search.form.from')}
      />
      <NumberField
        name="land_area_to"
        placeholder={t('search.form.to')}
        id="new-search-form-land-area-to"
      />
    </div>
  </div>
);

NewLandAreaFields.propTypes = {
  t: PropTypes.func
};

export default NewLandAreaFields;
