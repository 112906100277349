import React from 'react';
import { useForm } from 'react-final-form';
import PropTypes from 'prop-types';
import NewChips from '../../common/NewChips';

const repairType = [
  {
    value: '',
    name: 'new_search.whatever',
    id: 'new-search-form-whatever-repair'
  },
  {
    value: 'true',
    name: 'search.form.has_repair',
    id: 'new-search-form-has-repair'
  },
  {
    value: 'false',
    name: 'search.form.has_no_repair',
    id: 'new-search-form-no-repair'
  }
];

const NewSearchRepairType = ({ t, value }) => {
  const { change } = useForm();

  const onChangeRadioHandler = e => {
    const val = e.target.value === '' ? undefined : e.target.value;
    change('has_repair', val);
  };

  return (
    <div className="filters__section">
      <p className="filters__secondary-text">{t('new_search.repair')}</p>
      <div className="filters__options-wrapper">
        {repairType.map(repair => (
          <NewChips
            id={repair.id}
            key={repair.id}
            stat={repair.id}
            name="has_repair"
            type="radio"
            value={repair.value}
            text={t(repair.name)}
            storedValue={value || ''}
            onClick={onChangeRadioHandler}
          />
        ))}
      </div>
    </div>
  );
};

NewSearchRepairType.propTypes = {
  t: PropTypes.func,
  value: PropTypes.string
};

export default NewSearchRepairType;
