import React from 'react';
import PropTypes from 'prop-types';
import NewChips from '../../common/NewChips';

const buildingType = [
  {
    value: 'BUSINESS_CENTER',
    id: 'new-search-form-business-center',
    name: 'search.form.building_type_group.business'
  },
  {
    value: 'APARTMENT',
    id: 'new-search-form-house-flat',
    name: 'search.form.building_type_group.apartment'
  },
  {
    value: 'VILLA',
    id: 'new-search-form-villa-building',
    name: 'search.form.building_type_group.villa'
  }
];

const NewSearchBuildingType = ({ t, value }) => (
  <div className="filters__section">
    <p className="filters__secondary-text">
      {t('search.form.building_type_group.title')}
    </p>
    <div className="filters__options-wrapper">
      {buildingType.map(building => (
        <NewChips
          type="checkbox"
          key={building.id}
          stat={building.id}
          storedValue={value}
          value={building.value}
          name="building_type"
          text={t(building.name)}
        />
      ))}
    </div>
  </div>
);

NewSearchBuildingType.propTypes = {
  t: PropTypes.func,
  value: PropTypes.array
};

export default NewSearchBuildingType;
