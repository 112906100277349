import React from 'react';
import PropTypes from 'prop-types';
import NumberField from '../../common/NumberField';

const NewSearchArea = ({ t, isLand }) => (
  <div className="filters__section">
    <p className="filters__secondary-text__number">
      {t('search.form.area')}
      <span> </span>
      {isLand ? t('search.form.units.acres') : t('search.form.units.metres')}
    </p>
    <div className="filters__fields-container">
      <NumberField
        name="area_from"
        id="new-search-form-area-from"
        stat="new-search-form-area-from"
        placeholder={t('search.form.from')}
      />
      <NumberField
        name="area_to"
        id="new-search-form-area-to"
        stat="new-search-form-area-to"
        placeholder={t('search.form.to')}
      />
    </div>
  </div>
);

NewSearchArea.propTypes = {
  t: PropTypes.func,
  isLand: PropTypes.bool
};

export default NewSearchArea;
