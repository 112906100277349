import React from 'react';
import PropTypes from 'prop-types';
import NewResetButton from '../NewResetButton';

const FiltersHeader = ({
  title,
  close,
  handleReset,
  isDisabled,
  textColor
}) => {
  return (
    <div className="new-search-popup__header">
      <div className="new-search-popup__header-action">
        <button
          className="new-search-popup__header-btn new-search-popup__header-btn--close"
          onClick={close}
        />
      </div>
      <h2
        className="new-search-popup__header-title"
        data-cy="new-search-popup-title"
      >
        {title}
      </h2>
      {handleReset && (
        <NewResetButton
          textColor={textColor}
          isDisabled={isDisabled}
          handleReset={handleReset}
        />
      )}
    </div>
  );
};

FiltersHeader.propTypes = {
  close: PropTypes.func,
  title: PropTypes.string,
  isDisabled: PropTypes.bool,
  textColor: PropTypes.string,
  handleReset: PropTypes.func
};

export default FiltersHeader;
