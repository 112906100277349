import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const NewInput = ({
  id,
  stat,
  isPin,
  value,
  refEl,
  isSearch,
  onKeyDown,
  inputMode,
  placeholder,
  isShowReset,
  onBlurHandler,
  onResetHandler,
  onFocusHandler,
  onChangeHandler
}) => {
  useEffect(() => {
    if (isSearch && refEl.current) {
      refEl.current.focus();
    }
  }, [isSearch]);

  return (
    <div
      data-stat={stat}
      className={`new-search-input__container ${isPin ? 'is-pin' : ''}`}
    >
      <input
        id={id}
        ref={refEl}
        type="search"
        name="search"
        value={value}
        tabIndex={-1}
        autoComplete="off"
        onKeyDown={onKeyDown}
        inputMode={inputMode}
        onBlur={onBlurHandler}
        onFocus={onFocusHandler}
        placeholder={placeholder}
        onChange={onChangeHandler}
        className="new-search-input"
        pattern={inputMode === 'numeric' ? '[0-9]*' : null}
      />
      {isShowReset && (
        <div
          role="button"
          tabIndex={-1}
          onClick={onResetHandler}
          onKeyPress={onResetHandler}
          className="new-search-input__reset-btn"
        />
      )}
    </div>
  );
};

NewInput.propTypes = {
  id: PropTypes.string,
  isPin: PropTypes.bool,
  stat: PropTypes.string,
  value: PropTypes.string,
  refEl: PropTypes.object,
  isSearch: PropTypes.bool,
  onKeyDown: PropTypes.func,
  inputMode: PropTypes.string,
  isShowReset: PropTypes.bool,
  placeholder: PropTypes.string,
  onBlurHandler: PropTypes.func,
  onFocusHandler: PropTypes.func,
  onResetHandler: PropTypes.func,
  onChangeHandler: PropTypes.func
};

NewInput.defaultProps = {
  isSearch: false
};

export default NewInput;
