import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const NewTabPane = ({ children, name, additionalClasses }) => {
  const tabPaneClasses = classNames(
    'new-tabs__content_pane',
    `new-tabs__content_pane--${name}`,
    additionalClasses
  );
  return (
    <div className={tabPaneClasses} id={`tab-${name}`}>
      {children}
    </div>
  );
};

NewTabPane.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
  additionalClasses: PropTypes.string
};

export default NewTabPane;
