import React from 'react';
import PropTypes from 'prop-types';
import Condition from '../../common/Condition';
import NumberField from '../../common/NumberField';
import NewCheckbox from '../../common/NewCheckbox';
import searchFormConditionsValues from '../../../../defaultData/searchFormConditionsValues';

const priceType = [
  {
    name: 'search.form.has_bill_of_sale',
    id: 'has_bill_of_sale',
    stat: 'new-search-form-has-bill-of-sale'
  },
  {
    name: 'search.form.has_mortgage',
    id: 'has_mortgage',
    stat: 'new-search-form-has-mortgage'
  }
];

const NewSearchPrice = ({ t, valueHasMortgage, valueHasBillOfSale }) => (
  <div className="filters__section">
    <p className="filters__secondary-text__number">{t('search.form.price')}</p>
    <div className="filters__fields-container">
      <NumberField
        name="price_from"
        id="new-search-form-price-from"
        stat="new-search-form-price-from"
        placeholder={t('search.form.from')}
      />
      <NumberField
        name="price_to"
        id="new-search-form-price-to"
        stat="new-search-form-price-to"
        placeholder={t('search.form.to')}
      />
    </div>
    <Condition
      when="leased"
      is={searchFormConditionsValues.has_bill_of_sale}
      fields={['has_bill_of_sale', 'has_mortgage']}
    >
      <div className="filters__options-wrapper">
        {priceType.map(price => (
          <NewCheckbox
            key={price.id}
            name={price.id}
            stat={price.stat}
            text={t(price.name)}
            storedValue={
              price.id === 'has_mortgage'
                ? valueHasMortgage
                : valueHasBillOfSale
            }
          />
        ))}
      </div>
    </Condition>
  </div>
);

NewSearchPrice.propTypes = {
  t: PropTypes.func,
  valueHasMortgage: PropTypes.bool,
  valueHasBillOfSale: PropTypes.bool
};

export default NewSearchPrice;
