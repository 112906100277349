import React, { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import FiltersHeader from '../../common/FiltersHeader';
import BinaModal from '../../../ReactModal';
import NewInput from '../../common/NewInput';
import useSearchModal from '../../../../hooks/useSearchModal';
import {
  hidePopupHeader,
  showPopupHeader,
  setChosenLocationNames,
  setLocationIds
} from '../../../../actions';

const NewSearchPopupLocations = ({
  undo,
  isOpen,
  modalId,
  children,
  listType,
  topTools,
  inputMode,
  popupTitle,
  closeHandler,
  additionalClass,
  inputPlaceholder
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const inputEl = useRef(null);
  const formEl = useRef(null);
  const locationsResetButtonIsDisabled = useSelector(
    state => state.searchForm.locationsResetButtonIsDisabled
  );
  const {
    isFocused,
    searchString,
    onBlurHandler,
    onFocusHandler,
    onResetHandler,
    isInputOnFocus,
    onChangeHandler,
    isSubmittedForm,
    setIsSubmittedForm,
    defaultCancelHandler
  } = useSearchModal(inputEl);

  const setRef = () => {
    return formEl;
  };

  const handleReset = () => {
    dispatch(setLocationIds([]));
    dispatch(setChosenLocationNames([]));
  };

  const handleKeyDown = e => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      setIsSubmittedForm(true);
    }
  };

  const cancelHandler = () => {
    dispatch(showPopupHeader());
    defaultCancelHandler();
  };

  const onFocusInputHandler = () => {
    dispatch(hidePopupHeader());
    onFocusHandler();
  };

  const handleCloseWithUndo = useCallback(() => {
    undo();
    closeHandler();
  }, [undo, closeHandler]);

  return (
    <BinaModal
      isOpen={isOpen}
      modalId={modalId}
      onRequestClose={closeHandler}
      overlayClassName="new-search-popup__overlay"
      contentClassName={classNames('new-search-popup', additionalClass)}
    >
      <FiltersHeader
        title={popupTitle}
        close={handleCloseWithUndo}
        handleReset={handleReset}
        isDisabled={locationsResetButtonIsDisabled}
      />
      <div>
        {inputPlaceholder && (
          <div
            className={classNames('new-search-popup__tools', {
              'new-search-popup__tools--is-focused': isFocused
            })}
          >
            <NewInput
              isPin={true}
              refEl={inputEl}
              value={searchString}
              inputMode={inputMode}
              onKeyDown={handleKeyDown}
              isShowReset={!!searchString}
              onBlurHandler={onBlurHandler}
              placeholder={inputPlaceholder}
              stat="new-search-find-locations"
              onResetHandler={onResetHandler}
              isSearch={listType === 'search'}
              onChangeHandler={onChangeHandler}
              onFocusHandler={onFocusInputHandler}
            />
            {isFocused && (
              <button
                className="new-search-popup__content-btn new-search-popup__content-btn--cancel"
                onClick={cancelHandler}
              >
                {t('common.cancel')}
              </button>
            )}
          </div>
        )}
        {topTools}
        {typeof children === 'function'
          ? children(
              searchString,
              isFocused,
              cancelHandler,
              isSubmittedForm,
              setRef,
              isInputOnFocus
            )
          : { children }}
      </div>
    </BinaModal>
  );
};

NewSearchPopupLocations.propTypes = {
  undo: PropTypes.func,
  isOpen: PropTypes.bool,
  topTools: PropTypes.node,
  modalId: PropTypes.string,
  inputMode: PropTypes.string,
  closeHandler: PropTypes.func,
  popupTitle: PropTypes.string,
  additionalClass: PropTypes.string,
  inputPlaceholder: PropTypes.string,
  listType: PropTypes.string.isRequired,
  children: PropTypes.func || PropTypes.node
};

NewSearchPopupLocations.defaultProps = {
  reset: false,
  inputMode: 'text'
};

export default NewSearchPopupLocations;
