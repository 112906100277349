import React from 'react';
import PropTypes from 'prop-types';
import NewChips from '../../common/NewChips';

const NewSearchRooms = ({ t, value }) => (
  <div className="filters__section">
    <p className="filters__secondary-text">{t('search.form.rooms_number')}</p>
    <div className="filters__options-wrapper">
      {['1', '2', '3', '4', '5+'].map(room => (
        <NewChips
          text={room}
          value={room}
          name="room_ids"
          type="checkbox"
          key={`room-${room}`}
          storedValue={value || []}
          stat={`new-search-form-${room}-rooms`}
          cypress="rooms-chip-text"
        />
      ))}
    </div>
  </div>
);

NewSearchRooms.propTypes = {
  t: PropTypes.func,
  value: PropTypes.array
};

export default NewSearchRooms;
