import React, { useEffect, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Query } from '@apollo/client/react/components';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import NewSearchCities from '../NewSearchCities';
import Button from '../../Button';
import BinaModal from '../../ReactModal';
import { searchTotalCountQuery } from '../../../queries';
import { setSuffix } from '../../../utils/helpers';
import {
  setChosenLocationNames,
  setCurrentCity,
  setCurrentState,
  setLocationIds
} from '../../../actions';
import NewSearchLocations from '../NewSearchLocations';
import FiltersHeader from '../common/FiltersHeader';
import Condition from '../common/Condition';
import searchFormConditionsValues from '../../../defaultData/searchFormConditionsValues';
import NewSearchArea from './components/NewSearchArea';
import NewSearchRooms from './components/NewSearchRooms';
import NewSearchPrice from './components/NewSearchPrice';
import NewSearchLeased from './components/NewSearchLeased';
import NewSearchFloors from './components/NewSearchFloors';
import NewLandAreaFields from './components/NewLandAreaFields';
import NewSearchCategory from './components/NewSearchCategory';
import NewRentalPeriod from './components/NewRentalPeriod';
import NewSearchRepairType from './components/NewSearchRepairType';
import NewSearchBuildingType from './components/NewSearchBuildingType';
import NewSearchNewOrSecondaryEstate from './components/NewOrSecondaryEstate';

const NewSearchFilters = ({
  isOpen,
  hide,
  undo,
  formEl,
  modalParams,
  searchVariables,
  initialFormValues = {}
}) => {
  const dispatch = useDispatch();
  const renderProps = useFormState();
  const { t, i18n } = useTranslation();
  const { submit } = useForm();
  const resetButtonIsDisabled = useSelector(
    state => state.searchForm.resetButtonIsDisabled
  );
  const currentCity = useSelector(state => state.searchForm.currentCity);
  const [citiesLoading, setCitiesIsLoading] = useState(false);
  const [citiesPopupIsOpen, setCitiesPopupIsOpen] = useState(
    modalParams?.initialScreen === 'city'
  );
  const [locationsPopupIsOpen, setLocationsPopupIsOpen] = useState(
    modalParams?.initialScreen === 'locations'
  );
  const categoryFlat = ['1', '2', '3'].includes(renderProps.values.category_id);

  useEffect(() => {
    setLocationsPopupIsOpen(modalParams?.initialScreen === 'locations');
    setCitiesPopupIsOpen(modalParams?.initialScreen === 'city');
  }, [modalParams]);
  const [locationsPopupWillOpen, setLocationsPopupWillOpen] = useState(false);

  const toggleHandlerCities = () => {
    const citiesAreOpeningFromLocations =
      !citiesPopupIsOpen && locationsPopupIsOpen;
    const citiesWasOpenedFromLocations =
      citiesPopupIsOpen && locationsPopupWillOpen;
    if (citiesAreOpeningFromLocations) {
      setLocationsPopupWillOpen(true);
      setLocationsPopupIsOpen(false);
    } else if (citiesWasOpenedFromLocations) {
      setLocationsPopupWillOpen(false);
      setLocationsPopupIsOpen(true);
    }
    setCitiesPopupIsOpen(!citiesPopupIsOpen);
  };

  const toggleHandlerLocations = () => {
    if (modalParams?.initialScreen === 'locations') {
      hide();
    }
    setLocationsPopupIsOpen(!locationsPopupIsOpen);
  };

  const formResetHandler = () => {
    const resetData = {
      city_id: '0',
      leased: 'false',
      category_id: '1',
      locations_ids: undefined
    };
    dispatch(setCurrentCity('0'));
    formEl.current.reset(resetData);
    dispatch(setLocationIds([]));
    dispatch(setChosenLocationNames([]));
    dispatch(setCurrentState(resetData));
  };

  return (
    <BinaModal
      isOpen={isOpen}
      onRequestClose={hide}
      modalId="new-search"
      overlayClassName="filters__overlay"
      contentClassName="filters__content"
    >
      <div className="filters__last-block">
        <FiltersHeader
          close={undo}
          isDisabled={resetButtonIsDisabled}
          title={t('search.filters.title')}
          handleReset={formResetHandler}
        />
        <div className="filters__section filters__leased-spacing">
          <NewSearchCities
            citiesPopupIsOpen={citiesPopupIsOpen}
            setCitiesLoading={setCitiesIsLoading}
            toggleHandlerCities={toggleHandlerCities}
          />
          <div className="divider" />
          <NewSearchLocations
            modalParams={modalParams}
            currentCity={currentCity}
            formValues={renderProps.values}
            toggleHandlerCities={toggleHandlerCities}
            locationsPopupIsOpen={locationsPopupIsOpen}
            locationsPopupWillOpen={locationsPopupWillOpen}
            toggleHandlerLocations={toggleHandlerLocations}
            isInitialScreen={modalParams?.initialScreen === 'locations'}
          />
          <div className="divider" />
          <NewSearchLeased
            t={t}
            value={renderProps.values?.leased}
            defaultValue={initialFormValues.leased}
          />
        </div>
        <NewSearchCategory t={t} value={renderProps.values?.category_id} />
        {categoryFlat && (
          <NewSearchNewOrSecondaryEstate
            t={t}
            value={renderProps.values?.category_id}
          />
        )}
        <Condition
          when="category_id"
          is={searchFormConditionsValues.building_type}
          fields={['building_type']}
        >
          <NewSearchBuildingType
            t={t}
            value={renderProps.values?.building_type}
          />
        </Condition>
        <NewSearchPrice
          t={t}
          valueHasMortgage={renderProps.values?.has_mortgage}
          valueHasBillOfSale={renderProps.values?.has_bill_of_sale}
        />
        <Condition
          when="category_id"
          is={searchFormConditionsValues.has_repair}
          fields={['has_repair']}
        >
          <NewSearchRepairType t={t} value={renderProps.values?.has_repair} />
        </Condition>
        <Condition
          when="leased"
          is={searchFormConditionsValues.paid_daily}
          fields={['paid_daily']}
        >
          <NewRentalPeriod t={t} value={renderProps.values?.paid_daily} />
        </Condition>
        <Condition
          when="category_id"
          is={searchFormConditionsValues.room_ids}
          fields={['room_ids']}
        >
          <NewSearchRooms t={t} value={renderProps.values?.room_ids} />
        </Condition>
        <NewSearchArea t={t} isLand={renderProps.values?.category_id === '9'} />
        <Condition
          when="category_id"
          is={searchFormConditionsValues.land_area}
          fields={['land_area_from', 'land_area_to']}
        >
          <NewLandAreaFields t={t} />
        </Condition>
        <Condition
          when="category_id"
          is={searchFormConditionsValues.floors}
          fields={['floor_from', 'floor_to', 'floor_first', 'floor_last']}
        >
          <NewSearchFloors
            t={t}
            valueFirst={renderProps.values?.floor_first}
            valueLast={renderProps.values?.floor_last}
          />
        </Condition>
      </div>
      <Button
        name="button"
        onClickHandler={() => {
          submit();
          hide();
        }}
        type="button"
        id="new-search-form-submit-button"
        stat="new-search-form-submit-button"
        additionalClass="filters__submit-button"
        disabled={renderProps.submitting || citiesLoading}
      >
        <Query
          ssr={false}
          variables={searchVariables}
          fetchPolicy="cache-and-network"
          query={searchTotalCountQuery(searchVariables.filter)}
        >
          {({ loading, data, error }) => {
            if (error) return t('search.form.show_ads');
            const count = data?.itemsConnection.totalCount;

            return loading || renderProps.submitting ? (
              <span className="f-loader" />
            ) : (
              t(setSuffix(i18n, count, 'search.form.show_n_ads'), {
                n: count
              })
            );
          }}
        </Query>
      </Button>
    </BinaModal>
  );
};

NewSearchFilters.propTypes = {
  undo: PropTypes.func,
  hide: PropTypes.func,
  isOpen: PropTypes.bool,
  formEl: PropTypes.object,
  modalParams: PropTypes.object,
  renderProps: PropTypes.object,
  searchVariables: PropTypes.object,
  initialFormValues: PropTypes.object
};

export default NewSearchFilters;
