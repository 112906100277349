import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import Icon from '../../Icon';
import { fillCategoriesWithData } from '../../../utils/helpers/search';
import { categoriesQuery } from '../../../queries';
import { setError } from '../../../actions';

const NewSearchCategory = ({ icon, text, onClick, stat }) => {
  return (
    <div
      tabIndex={0}
      role="button"
      data-stat={stat}
      onClick={onClick}
      onKeyPress={onClick}
      className="category-container"
    >
      <div className="category__icon">
        <Icon
          width={22}
          height={22}
          color="#fff"
          indent={false}
          IconComponent={icon}
        />
      </div>
      <span className="category__text">{text}</span>
    </div>
  );
};

NewSearchCategory.propTypes = {
  icon: PropTypes.func,
  stat: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func
};

const NewSearchCategories = ({ change, submit }) => {
  const dispatch = useDispatch();
  const { data, loading, error } = useQuery(categoriesQuery);
  const categoriesData = (loading && []) || data?.categories;
  const filledCategories = fillCategoriesWithData(categoriesData);

  useEffect(() => {
    if (error) dispatch(setError(true));
  }, [error]);

  return (
    <div className="categories-container">
      {filledCategories.map(category => (
        <NewSearchCategory
          key={category.id}
          stat={category.stat}
          icon={category.icon}
          text={category.name}
          onClick={() => {
            change('category_id', category.id);
            change('category_touched', true);
            submit();
          }}
        />
      ))}
    </div>
  );
};

NewSearchCategories.propTypes = {
  change: PropTypes.func,
  submit: PropTypes.func
};

export default NewSearchCategories;
