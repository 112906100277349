import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const NewSearchEmptyLocations = ({ onClickHandler, linkText }) => {
  const { t } = useTranslation();

  return (
    <div className="new-search-empty-locations">
      <p className="new-search-empty-locations__text">
        {t('new_search.no_locations')}
      </p>
      {/* eslint-disable */}
      <a
        href="#"
        onClick={onClickHandler}
        className="new-search-empty-locations__link"
      >
        {linkText}
      </a>
      {/* eslint-disable */}
    </div>
  );
};

NewSearchEmptyLocations.propTypes = {
  linkText: PropTypes.string.isRequired,
  onClickHandler: PropTypes.func.isRequired
};

export default NewSearchEmptyLocations;
