import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const NewResetButton = ({ handleReset, isDisabled, textColor }) => {
  const { t } = useTranslation();

  return (
    <div className="new-search-popup__header-action__right">
      <button
        className={classNames('new-search-popup__header-btn', {
          disabled: isDisabled
        })}
        onClick={handleReset}
        style={{ color: textColor }}
        data-cy="new-search-popup-reset-btn"
      >
        {t('common.reset')}
      </button>
    </div>
  );
};

NewResetButton.defaultProps = {
  textColor: '#4264FD'
};

NewResetButton.propTypes = {
  textColor: PropTypes.string,
  handleReset: PropTypes.func,
  isDisabled: PropTypes.bool
};

export default NewResetButton;
