import { useState } from 'react';

export default input => {
  const [searchString, setSearchString] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [isInputOnFocus, setIsInputOnFocus] = useState(false);
  const [isSubmittedForm, setIsSubmittedForm] = useState(false);

  const onChangeHandler = e => {
    setSearchString(e.target.value);
    if (e.target.value.length < 5) {
      setIsSubmittedForm(false);
    }
  };
  const onFocusHandler = () => {
    setIsFocused(true);
    setIsInputOnFocus(true);
    input.current.click();
  };
  const onBlurHandler = () => {
    setIsInputOnFocus(false);
  };
  const defaultCancelHandler = () => {
    setIsFocused(false);
    setSearchString('');
    setIsSubmittedForm(false);
  };
  const onResetHandler = () => {
    setSearchString('');
    input.current.focus();
    setIsSubmittedForm(false);
  };

  return {
    searchString,
    isFocused,
    onChangeHandler,
    onFocusHandler,
    onBlurHandler,
    defaultCancelHandler,
    onResetHandler,
    isSubmittedForm,
    setIsSubmittedForm,
    isInputOnFocus
  };
};
