import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import NewTab from './NewTab';

const NewTabs = ({ children, switchTab, selected, scopeClass, titles }) => {
  const renderTitles = () => {
    const tabTitles = titles || children;

    return (
      <ul className="new-tabs__list">
        {tabTitles.map((tab, i) => {
          const tabData = tab.props || tab;

          return (
            <NewTab
              key={i}
              name={tabData.name}
              text={tabData.label}
              currentTabId={selected}
              switchTab={switchTab}
              tabId={i}
              itemsCount={tabData.itemsCount}
            />
          );
        })}
      </ul>
    );
  };

  const selectedChild = React.Children.toArray(children)[selected];
  const renderContent = (
    <div className="new-tabs__content">{selectedChild}</div>
  );

  return (
    <div className={classNames('new-tabs', `new-tabs--${scopeClass}`)}>
      {renderTitles()}
      {renderContent}
    </div>
  );
};

NewTabs.propTypes = {
  selected: PropTypes.number,
  switchTab: PropTypes.func,
  children: PropTypes.node,
  scopeClass: PropTypes.string,
  titles: PropTypes.array
};

export default NewTabs;
