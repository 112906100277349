import React, { memo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { Field, useForm, useFormState } from 'react-final-form';
import {
  setChosenLocationNames,
  setCurrentCity,
  setCurrentCityIsDeferred,
  setCurrentState,
  setError,
  setLocationIds
} from '../../../actions';
import { cityQuery } from '../../../queries';
import NewSearchPopupLocations from './NewSearchPopupLocations';
import NewSearchLocation from './NewSearchLocation';
import useLocationsPlaceholder from '../../../hooks/useLocationsPlaceholder';
import Loader from '../../Loader';

const NewSearchLocations = ({
  listType,
  formValues,
  locationsPopupIsOpen,
  locationsPopupWillOpen,
  currentCity,
  modalParams,
  isInitialScreen,
  toggleHandlerLocations,
  toggleHandlerCities
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { change } = useForm();
  const { values } = useFormState();
  const storedCity = useSelector(state => state.searchForm.currentCity);
  const currentState = useSelector(state => state.searchForm.currentState);
  const locationIds = useSelector(state => state.searchForm.locationIds);
  const chosenLocationNames = useSelector(
    state => state.searchForm.chosenLocationNames
  );
  const cityId = currentCity || formValues.city_id || storedCity;
  const { locationsPlaceholder, groupsAmount } =
    useLocationsPlaceholder(cityId);
  const noLocations = groupsAmount === 0;

  const { data, loading, error } = useQuery(
    cityQuery({
      id: cityId
    }),
    {
      variables: {
        id: cityId
      }
    }
  );

  useEffect(() => {
    if (error) dispatch(setError(true));
  }, [error]);

  const cityName =
    data && data.city !== null ? data.city.name : t('search.city_search.any');

  const popupTopTools = () => {
    return (
      <div
        style={{ marginTop: noLocations ? '45px' : 0 }}
        className="new-search-popup__top-tools-container"
      >
        <div className="new-search-popup__top-tools">
          <span
            tabIndex="0"
            role="button"
            data-cy="city-container"
            onClick={toggleHandlerCities}
            onKeyPress={toggleHandlerCities}
            className="new-search-form__city"
          >
            <div className="new-search-form__city_label" data-cy="city-label">
              {t('new_search.your_city')}
            </div>
            <Loader
              loading={loading}
              optionalClass="new-search-popup__top-tools_loader"
            >
              <div className="new-search-form__city_name">{cityName}</div>
            </Loader>
          </span>
        </div>
      </div>
    );
  };

  useEffect(() => {
    dispatch(setCurrentCityIsDeferred(locationsPopupWillOpen));
  }, [locationsPopupWillOpen]);

  const handleUndo = useCallback(() => {
    if (!isInitialScreen) {
      return;
    }
    Object.keys(values).forEach(key => {
      change(key, values[key]);
    });
    dispatch(setChosenLocationNames(chosenLocationNames));
    dispatch(setLocationIds(locationIds));
    dispatch(setCurrentCity(currentCity));
    dispatch(setCurrentState(currentState));
  }, []);

  return (
    <div>
      <Field name="location_ids" component="input" type="hidden" />
      {locationsPlaceholder && (
        <div
          role="button"
          tabIndex="0"
          data-cy="chosen-locations"
          onClick={toggleHandlerLocations}
          id="new-search-form-locations-row"
          onKeyPress={toggleHandlerLocations}
          className="new-search__locations_row"
          data-stat="new-search-form-locations"
        >
          {chosenLocationNames?.length ? (
            <div className="new-search__locations__label">
              <div className="city__label">{locationsPlaceholder}</div>
              <div
                className="new-search__locations_names"
                data-cy="chosen-locations-names"
              >
                {chosenLocationNames.join(', ')}
              </div>
            </div>
          ) : (
            <div
              className="new-search__locations_placeholder"
              data-cy="chosen-locations-placeholder"
            >
              {locationsPlaceholder}
            </div>
          )}
        </div>
      )}
      <NewSearchPopupLocations
        undo={handleUndo}
        listType={listType}
        modalId="locations"
        closeHandler={toggleHandlerLocations}
        isOpen={locationsPopupIsOpen}
        popupTitle={t('search.locations_search.title')}
        additionalClass="new-search-popup--locations"
        topTools={popupTopTools(formValues)}
        inputPlaceholder={noLocations ? '' : locationsPlaceholder}
      >
        {(
          searchString,
          isFocused,
          cancelHandler,
          isSubmittedForm,
          setRef,
          isInputOnFocus
        ) => {
          return (
            <NewSearchLocation
              noLocations={noLocations}
              toggleHandlerCities={toggleHandlerCities}
              closePopupHandler={toggleHandlerLocations}
              {...{
                modalParams,
                isFocused,
                searchString,
                cancelHandler,
                isInputOnFocus
              }}
            />
          );
        }}
      </NewSearchPopupLocations>
    </div>
  );
};

NewSearchLocations.propTypes = {
  isInitialScreen: PropTypes.bool,
  modalParams: PropTypes.object,
  listType: PropTypes.string,
  formValues: PropTypes.object,
  locationsPopupIsOpen: PropTypes.bool,
  locationsPopupWillOpen: PropTypes.bool,
  currentCity: PropTypes.string,
  toggleHandlerLocations: PropTypes.func,
  toggleHandlerCities: PropTypes.func,
  setErrorUI: PropTypes.func
};

NewSearchLocations.defaultProps = {
  listType: 'search'
};

export default memo(NewSearchLocations);
