import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { useForm } from 'react-final-form';
import NewRadioButton from '../../../NewRadioButton';
import useIphoneDetection from '../../../../hooks/useIphoneDetection';
import { citiesListQuery } from '../../../../queries';
import Loader from '../../../Loader';
import NewSearchEmpty from '../NewSearchEmpty';
import {
  setCitiesList,
  showPopupHeader,
  setChosenLocationNames,
  setCurrentCity,
  setError,
  setCurrentState,
  setLocationIds
} from '../../../../actions';
import { entitiesFilter } from '../../../../utils/helpers/common';

const NewSearchCity = ({
  closePopupHandler,
  cancelHandler,
  isFocused,
  searchString
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { change, getState } = useForm();
  const { data, loading, error } = useQuery(citiesListQuery);
  const isIphone = useIphoneDetection();
  const currentCity = useSelector(state => state.searchForm.currentCity);
  const storedFormState = useSelector(state => state.searchForm.currentState);

  const items = [
    {
      id: '0',
      name: t('search.city_search.any'),
      slug: null,
      __typename: 'City'
    }
  ].concat((data && data.cities) || []);

  useEffect(() => {
    dispatch(setCitiesList(items));
  }, [data]);

  useEffect(() => {
    if (error) dispatch(setError(true));
  }, [error]);

  useEffect(() => {
    const citiesPopup = document.getElementsByClassName('search-popup--cities');
    const container = citiesPopup[0]?.querySelector('.search-popup__container');
    const touchHandler = () => {
      const input = citiesPopup[0].querySelector('input[type="search"]');

      input.blur();
    };

    if (isIphone) {
      container?.addEventListener('touchstart', touchHandler);
    }

    return () => {
      if (isIphone) {
        container && container.removeEventListener('touchstart', touchHandler);
      }
    };
  }, [isIphone]);

  const onChangeRadioHandler = e => {
    const city = e.target.value;
    dispatch(setCurrentCity(city));
    dispatch(
      setCurrentState({
        ...storedFormState,
        city_id: city,
        location_ids: undefined
      })
    );
    change('city_id', city);
    change('location_ids', undefined);
    dispatch(setLocationIds([]));
    dispatch(setChosenLocationNames([]));
    dispatch(showPopupHeader());
    closePopupHandler();
    cancelHandler();
  };
  const citiesList =
    (!isFocused || searchString) &&
    entitiesFilter(items, searchString).map(city => (
      <NewRadioButton
        key={city.id}
        data={city}
        onChangeHandler={onChangeRadioHandler}
        currentValue={currentCity || getState().values.city_id}
        name="search-city"
        cypress="city"
      />
    ));
  const emptySearch = !citiesList.length && searchString && (
    <NewSearchEmpty
      onClickHandler={cancelHandler}
      linkText={t('search.city_search.back')}
    />
  );

  if (error) {
    return null;
  }

  return (
    <div className="new-search-popup__container">
      <Loader loading={loading}>{citiesList}</Loader>
      {emptySearch}
    </div>
  );
};

NewSearchCity.propTypes = {
  closePopupHandler: PropTypes.func,
  cancelHandler: PropTypes.func,
  isFocused: PropTypes.bool,
  searchString: PropTypes.string
};

export default NewSearchCity;
