import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';

const Condition = ({ when, is, fields, children }) => (
  <>
    <Field name={when} subscription={{ value: true }}>
      {({ input: { value } }) => (is.includes(value) ? children : null)}
    </Field>
    {fields &&
      fields.map(setField => {
        return (
          <Field name={setField} subscription={{}} key={setField}>
            {({ input: { onChange } }) => (
              <OnChange name={when}>
                {value => {
                  if (!is.includes(value)) {
                    onChange(undefined);
                  }
                }}
              </OnChange>
            )}
          </Field>
        );
      })}
  </>
);

Condition.propTypes = {
  when: PropTypes.string,
  is: PropTypes.array,
  fields: PropTypes.array,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

export default Condition;
