import React, { useCallback, useEffect, useState } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '../../Icon';
import NewSearchFilters from '../NewSearchFilters';
import FilterIcon from '../../../public/static/images/new_search/icons/icon-filter.svg';
import FilterSmallIcon from '../../../public/static/images/new_search/icons/icon-filter-small.svg';
import DeleteIcon from '../../../public/static/images/new_search/icons/icon-delete.svg';
import {
  setChosenLocationNames,
  setCurrentCity,
  setCurrentState,
  setError,
  setLocationIds
} from '../../../actions';
import { cityQuery } from '../../../queries';

const NewSearchBar = ({ formEl, searchVariables, initialFormValues }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { values } = useFormState();
  const { change, submit } = useForm();
  const locationsPlaceholder = t(
    'search.locations_search.locations_placeholder.all_groups'
  );
  const currentState = useSelector(state => state.searchForm.currentState);
  const currentCity = useSelector(state => state.searchForm.currentCity);
  const locationIds = useSelector(state => state.searchForm.locationIds);
  const cityId = currentCity || values.city_id || '0';
  const chosenLocationNames = useSelector(
    state => state.searchForm.chosenLocationNames
  );
  const [modalParams, setModalParams] = useState(null);
  const hasChosenLocationsOrCity =
    chosenLocationNames?.length > 0 || (cityId && cityId !== '1');

  const { data, error, loading } = useQuery(cityQuery(cityId), {
    variables: {
      id: cityId
    }
  });

  const cityName =
    data && data.city !== null ? data.city.name : t('search.city_search.any');

  useEffect(() => {
    if (error) dispatch(setError(true));
  }, [error]);

  useEffect(() => {
    const scrollHandler = () => {
      const scrollButton = document.getElementById('search_float_button');
      if (window.pageYOffset > 300) {
        scrollButton.style.opacity = '1';
        scrollButton.style.visibility = 'visible';
      } else {
        scrollButton.style.opacity = '0';
        scrollButton.style.visibility = 'hidden';
      }
    };
    window.addEventListener('scroll', scrollHandler);

    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  const toggleHandlerLocations = () => {
    setModalParams({
      initialScreen: 'locations'
    });
  };

  const resetChosenLocations = event => {
    event.stopPropagation();
    dispatch(setCurrentCity('1'));
    change('city_id', 1);
    change('location_ids', undefined);
    dispatch(setLocationIds([]));
    dispatch(setChosenLocationNames([]));
    submit();
  };

  const openFilters = () =>
    setModalParams({
      initialScreen: 'filters'
    });

  const hideFilters = () => setModalParams(null);

  const handleHideWithUndo = useCallback(() => {
    Object.keys(values).forEach(key => {
      change(key, values[key]);
    });
    dispatch(setChosenLocationNames(chosenLocationNames));
    dispatch(setLocationIds(locationIds));
    dispatch(setCurrentCity(currentCity));
    dispatch(setCurrentState(currentState));
    hideFilters();
  }, []);

  return (
    <div className="search-bar-container">
      <div className="search-bar__wrapper">
        <div
          tabIndex="0"
          role="button"
          className="search-bar"
          onClick={toggleHandlerLocations}
          onKeyPress={toggleHandlerLocations}
          data-stat="new-search-search-bar-locations"
        >
          <Field name="location_ids" component="input" type="hidden" />
          <div style={{ width: '100%' }}>
            {(cityId !== '1' || chosenLocationNames.length) && !loading ? (
              <div
                className="new-search__locations_names"
                data-cy="chosen-locations-names"
              >
                {[cityName, ...chosenLocationNames].join(', ')}
              </div>
            ) : (
              <div
                className="new-search__locations_placeholder"
                data-cy="chosen-locations-placeholder"
              >
                {locationsPlaceholder}
              </div>
            )}
          </div>
        </div>
        {hasChosenLocationsOrCity && (
          <div
            tabIndex={0}
            role="button"
            className="search-bar__reset"
            onClick={resetChosenLocations}
            onKeyPress={resetChosenLocations}
          >
            <Icon
              width={15}
              height={15}
              indent={false}
              IconComponent={DeleteIcon}
            />
          </div>
        )}
      </div>
      <div
        tabIndex="0"
        role="button"
        onClick={openFilters}
        onKeyPress={openFilters}
        className="search-bar__filters"
        data-stat="new-search-filters-button"
      >
        <Icon
          width={20}
          height={20}
          indent={false}
          IconComponent={FilterIcon}
        />
      </div>
      <div
        tabIndex="0"
        role="button"
        onClick={openFilters}
        onKeyPress={openFilters}
        id="search_float_button"
        className="search-bar__filters__float"
        data-stat="new-search-filters-float-button"
      >
        <Icon width={18} height={18} IconComponent={FilterSmallIcon} />
        {t('search.buttons.filters')}
      </div>
      <NewSearchFilters
        formEl={formEl}
        hide={hideFilters}
        isOpen={!!modalParams}
        undo={handleHideWithUndo}
        modalParams={modalParams}
        searchVariables={searchVariables}
        initialFormValues={initialFormValues}
      />
    </div>
  );
};

NewSearchBar.propTypes = {
  formEl: PropTypes.object,
  searchVariables: PropTypes.object,
  initialFormValues: PropTypes.object
};

export default NewSearchBar;
