import React from 'react';
import PropTypes from 'prop-types';
import NewChips from '../../common/NewChips';

const newOrSecondaryEstate = [
  {
    value: '2',
    name: 'new_search.new_building',
    id: 'new-search-form-new-building'
  },
  {
    value: '3',
    name: 'new_search.secondary_building',
    id: 'new-search-form-second-hand-building'
  }
];

const NewSearchNewOrSecondaryEstate = ({ t, value }) => (
  <div className="filters__section">
    <p className="filters__secondary-text">
      {t('search.form.building_type_group.title')}
    </p>
    <div className="filters__options-wrapper">
      {newOrSecondaryEstate.map(building => (
        <NewChips
          type="radio"
          key={building.id}
          stat={building.id}
          storedValue={value}
          name="category_id"
          value={building.value}
          text={t(building.name)}
        />
      ))}
    </div>
  </div>
);

NewSearchNewOrSecondaryEstate.propTypes = {
  t: PropTypes.func,
  value: PropTypes.string
};

export default NewSearchNewOrSecondaryEstate;
